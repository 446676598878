.tab-bar {
    display: flex;
    padding: 0px;
    border: 2px solid #ffffffde;
    border-radius: 20px;
    overflow: hidden;
}

.tab-button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #a9a9a9;
    border-top: 2px solid transparent;
}

.tab-button.active {
    color: #fff;
    color: #fff;
    background-color: #00ff1cba;
}

.tab-button:not(.active):hover {
    /* border-top: 2px solid #ccc; */
    color: #fff;
}

.historydetails {
    margin-bottom: 30px;
    border-radius: 0px;
    box-shadow: 0px 0px 100px -80px #ffffff;
    border-radius: 6px;
    height: 100%;
    position: relative;
    display: block;
    text-align: center;
    opacity: 1;
    border: 1px solid #020004;
    padding: 20p;
    background-size: contain;
    background-color: #000;
}

.historyitem {
    position: relative;
    padding: 10px 20px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    background: rgba(58, 47, 47, 0.23);
}