body {
  font-size: 14px;
  background-color: #000;
  line-height: 1.4;
  color: #ffffff;
  position: relative;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
}
@font-face {
  font-family: "BudmoJiggler-Regular";
  src: url(../fonts/BudmoJiggler-Regular.eot);
  src: url("../fonts/BudmoJiggler-Regular.eot?#iefix")
      format("embedded-opentype"),
    url(../fonts/BudmoJiggler-Regular.woff2) format("woff2"),
    url(../fonts/BudmoJiggler-Regular.woff) format("woff"),
    url(../fonts/BudmoJiggler-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.bgBody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.col-6 {
  flex-basis: 0 0 50%;
  max-width: 50%;
}
.col-12 {
  flex-basis: 0 0 100%;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BudmoJiggler-Regular";
  font-weight: 700;
  line-height: 1;
}

.btnMU,
.btn-btnMU {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  background-color: #000109;
  text-decoration: none;
  border: transparent;
  --cui-btn-active-bg: transparent;

  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    background: linear-gradient(
      60deg,
      #ffb46a,
      #ff9984,
      #ff84a7,
      #f09eff,
      #97baff,
      #7bedff,
      #93fff1,
      #a1ffb8
    );
    border-radius: 6px;
    z-index: -1;
    animation: animatedgradient 0.9s ease alternate infinite;
    background-size: 300% 300%;
  }

  span {
    display: block;
    padding: 0px 12px;
  }

  &:hover {
    background: #050107;
  }
}
.btn-btnMU:nth-child(1) {
  &::after {
    animation: unset;
  }
}
.btn-btnMU:nth-child(2) {
  &::after {
    animation: unset;
  }
}
.btn-btnMU:nth-child(3) {
  &::after {
    animation: unset;
  }
}
.btn-btnMU:nth-child(4) {
  &::after {
    animation: unset;
  }
}
@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
    opacity: 0;
  }

  100% {
    background-position: 0 50%;
    opacity: 1;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
    opacity: 0;
  }

  100% {
    background-position: 0 50%;
  }
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

a {
  color: #83c8ff;
  outline: none;

  &:hover {
    color: #ffbebe;
  }
}

#root {
  min-height: 100vh;
}

main {
  padding: 40px 0px;

  section {
    margin-bottom: 80px;

    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
}

// Header Css Start
header {
  position: sticky;
  top: 0px;
  left: 0;
  width: 100%;
  // box-shadow: 0px 0px 70px -30px #8b8b8b;
  // background-color: #020315;
  background-image: linear-gradient(#020315, #02031500 100%);
  z-index: 2;

  .header {
    padding: 0px 0px;
    min-height: auto;
    background-color: transparent;
    border: none;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #020314;
      filter: blur(30px);
    }
  }

  .header-brand {
    img {
      width: 100%;
      max-width: 200px;
    }
  }

  .header-nav {
    a {
      padding: 0px 10px;
      text-transform: uppercase;
      text-decoration: none;

      &.active {
        color: #ffbebe;
      }
    }
  }

  .bgBody {
    position: absolute;
  }
  .logoMainHead {
    width: 140px;
  }
}
.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}

// Header Css End

// Footer Css Start
footer {
  position: sticky;
  top: 100%;
  left: 0;
  width: 100%;
  // box-shadow: 0px 0px 70px -30px #8b8b8b;

  .copyright {
    color: #ffffff;
  }

  .footer {
    background-color: transparent;
    border: none;
    padding: 60px 0px 15px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
  }

  .social-icons {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #83c8ff;
      border: 1px solid #83c8ff;
      border-radius: 3px;

      &:hover {
        border-color: #ffbebe;
        color: #ffbebe;
      }
    }
  }
}
.footer_bottom {
  padding: 20px 0;
  border-top: 1px solid #404040;
  background-color: #000;
}
.copyright_right {
  p {
    margin-bottom: 0px;
    font-size: 14px;
    color: #aaa6b9;
    font-weight: 500;
    text-transform: uppercase;
  }
  a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
  }
}
.footer_social {
  ul {
    margin-bottom: 0px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    margin-right: 10px;
    padding-right: 20px;
    position: relative;
    &::before {
      position: absolute;
      content: "||";
      right: 0;
      top: 50%;
      transform: translatey(-50%);
    }
  }
  a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    text-align: center;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
// Footer Css End

// Banner Css Start
.topInformation {
  margin-bottom: 30px;

  h1 {
    font-size: 55px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 1.3;
    font-family: "BudmoJiggler-Regular";
    margin-bottom: 30px;
    text-align: left;
    color: #3c2900;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff,
      0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656,
      0 0 11px #ffe656;

    .nftxt {
      color: #db9eff;
    }
  }

  .btnMU,
  .btn-btnMU {
    font-size: 20px;
    // min-width: 200px;
  }
}

.nftmainimg {
  img {
    max-height: 700px;
  }
}

.listViewBx {
  background-color: #000;
  border-radius: 6px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: linear-gradient(
      60deg,
      #ffb46a,
      #ff9984,
      #ff84a7,
      #f09eff,
      #97baff,
      #7bedff,
      #93fff1,
      #a1ffb8
    );
    border-radius: 6px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
  .inrInfoBx {
    text-align: center;
    margin: 0 auto;

    .imginrBx {
      position: relative;
      margin: 0 auto 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    .date {
     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
     font-size: 35px;
    } 
    span.countbox strong {
      // font-size: 7vw !important;
      font-size: clamp(1rem, 7vw, 130px);
      letter-spacing: 0.6rem;
      color: #181f1b;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #72eeaf, 0 0 2px #72eeaf, 0 0 0 #72eeaf, 0 0 2px #72eeaf, 0 0 11px #72eeaf;
  }

    p {
      font-size: 19px;
      margin-bottom: 5px;
    }

    strong {
      font-size: 25px;
      display: block;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    img {
      max-width: 100px;
      margin: 0 auto 0px;
      display: block;
      position: relative;
      animation: zoominout 3s alternate infinite;
    }
    @keyframes zoominout {
      0%,
      100% {
        transform: scale(1) rotate(-5deg);
      }

      50% {
        transform: scale(0.95) rotate(0);
      }
    }
  }
}
.lazyload-wrapper {
  // max-width: 600px;
  width:fit-content;
  margin: 10px auto;
  video {
    transform: scale(1);
    mix-blend-mode: lighten;
  }
}
.lazyload-wrapper svg {
  mix-blend-mode: difference;
}

// Banner Css End

// nft Balance Css Start
.nftBalance {
  .balanceViewBx {
    .balancewrap {
      margin-bottom: 30px;
      border-radius: 0px;
      box-shadow: 0px 0px 100px -80px #ffffff;
      border-radius: 6px;
      height: 100%;
      position: relative;
      display: block;
      text-align: center;
      // background: url(../images/nfts/Lvl1.gif);
      opacity: 1;
      border: 1px solid #020004;
      padding: 20p;
      background-size: contain;
      background-color: #000;
      &::before {
    content: ""; 
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    border-radius: 6px;
    background-size: 300% 300%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(1px);
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
    border-radius: 6px;
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
      }
    }
    .balancelist:first-child {
      border-radius: 6px 6px 0 0px;
    }
    .balancelist:last-child {
      border-radius: 0px 0px 6px 6px;
    }
    .balancelist {
      position: relative;
      padding: 10px 20px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      background: rgba(58, 47, 47, 0.23);
    

      // &::before {
      //   content: "";
      //   position: absolute;
      //   top: -2px;
      //   left: -2px;
      //   height: calc(100% + 4px);
      //   width: calc(100% + 4px);
      //   background: linear-gradient(
      //     60deg,
      //     #ffb46a,
      //     #ff9984,
      //     #ff84a7,
      //     #f09eff,
      //     #97baff,
      //     #7bedff,
      //     #93fff1,
      //     #a1ffb8
      //   );
      //   border-radius: 6px;
      //   z-index: -1;
      //   animation: animatedgradient 3s ease alternate infinite;
      //   background-size: 300% 300%;
      // }

      span {
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
        font-size: 16px;
      }
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }

      svg {
        margin-left: 10px;
        width: 22px;
        height: auto;
        display: inline-block;
        cursor: pointer;
      }
    }
    .gifbox {
      max-width: 120px;
      margin: 12px auto;
      z-index: 1;
      position: relative;
      // background: #cf95433d;
      background: rgb(58 47 47 / 46%);
      border-radius: 50%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
// nft Balance & List Css End

// ournft
.nftMain {

  .nftheadline {
    font-size: 42px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
      0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
    animation: pulsate 1.5s infinite alternate;
    font-weight: 400;
  }
  .leftinfo {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .rightinfo{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .stakebtnarea {
    position: relative;
    padding: 22px;
    text-align: center;
    span {
      font-size: 16px;
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 500;
      color: #fff;
      display: block;
    }
  }

  a {
    text-decoration: none;
  }
  .stakebtn {
    // background-color: #676767;
    padding: 9px 16px;
    border-radius: 6px;
    text-decoration: none;
    text-align: center;
    z-index: 9;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: -4px;
      left: -4px;
      height: calc(100% + 8px);
      width: calc(100% + 8px);
      background: linear-gradient(
        60deg,
        #ffb46a,
        #ff9984,
        #ff84a7,
        #f09eff,
        #97baff,
        #7bedff,
        #93fff1,
        #a1ffb8
      );
      border-radius: 6px;
      z-index: -1;
      background-size: 300% 300%;
    }
  }

  .popular_gaming_inner {
    .col-3 {
      flex-basis: 0 0 25%;
      max-width: 25%;
    }
    .swiper-button-prev {
      background-color: #873787;
      cursor: pointer;
      height: 50px;
      left: 0;
      position: absolute;
      top: 45%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 50px;
      z-index: 9999999;

      &::before {
        align-items: center;
        content: "↞";
        display: flex;
        font-size: 36px;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .swiper-button-next {
      background-color: #873787;
      cursor: pointer;
      height: 50px;
      position: absolute;
      right: 0;
      top: 45%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 50px;
      z-index: 9999999;

      &::before {
        align-items: center;
        content: "↠";
        display: flex;
        font-size: 36px;
        font-weight: 600;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
    
    .nftsvideo {
      border: 1px solid #686868;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      // background: linear-gradient(#212121, #212121) padding-box,
      //         linear-gradient(145deg, transparent 35%,#e81cff, #40c9ff) border-box;
      background: linear-gradient(#000, #000) padding-box,
              linear-gradient(145deg,#ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8) border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
  -webkit-transform-origin: right bottom;
      -ms-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
      // &::before {
      //   position: absolute;
      //   content: "";
      //   height: 60px;
      //   width: 100%;
      //   background: rgb(67 59 46 / 97%);
      //   bottom: 0;
      // }
    }

    .nftboxtitle {
      align-items: center;
      background-color: #686868;
      color: #fff;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      justify-content: center;
      left: -60px;
      padding: 7px 5px;
      position: absolute;
      text-align: center;
      top: 40px;
      -webkit-transform: rotate(-55deg);
      transform: rotate(-55deg);
      width: 200px;
      z-index: 1;
    }

    .swiper-slide {
      overflow: hidden;
    }
  }
}
.stakebtn {
  // background-color: #676767;
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  &::after {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    background: linear-gradient(
      60deg,
      #ffb46a,
      #ff9984,
      #ff84a7,
      #f09eff,
      #97baff,
      #7bedff,
      #93fff1,
      #a1ffb8
    );
    border-radius: 6px;
    z-index: -1;
    background-size: 300% 300%;
  }
}
// Connect Wallet css Start
.connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);

  .modal-dialog {
    max-width: 450px;
  }

  .modal-content {
    color: #ffffff;
    background-color: #0a0a0a;
    box-shadow: 0px 0px 70px -40px #666666;
    padding: 0px 20px;
    &::before {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      background: linear-gradient(
        60deg,
        #ffb46a,
        #ff9984,
        #ff84a7,
        #f09eff,
        #97baff,
        #7bedff,
        #93fff1,
        #a1ffb8
      );
      border-radius: 6px;
      z-index: -1;
      animation: animatedgradient 3s ease alternate infinite;
      background-size: 300% 300%;
    }

    .modal-header {
      align-items: flex-start;
      border: none;
      padding: 20px 10px;
    }

    .modal-body {
      padding: 10px;
    }

    button.btn-close {
      outline: none;
      box-shadow: none !important;
      opacity: 1;
      filter: invert(1);
    }

    .modal-title {
      font-size: 24px;
      margin-bottom: 0px;
      font-family: "BudmoJiggler-Regular";
      color: yellow;
      text-shadow: 0.3px 0.3px 0.3px #111;
      letter-spacing: 0.11em;
      color: #3c2900;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff,
        0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656,
        0 0 11px #ffe656;

      p {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        margin: 10px 0px 0px;
        text-shadow: none;
      }
    }
  }

  .popupbtngrp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .btngrp {
      flex-basis: calc(33.33% - 15px);
      max-width: calc(33.33% - 15px);
      margin: 0px 7px 20px;
      display: block;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        height: calc(100% + 4px);
        width: calc(100% + 4px);
        background: linear-gradient(
          60deg,
          #ffb46a,
          #ff9984,
          #ff84a7,
          #f09eff,
          #97baff,
          #7bedff,
          #93fff1,
          #a1ffb8
        );
        border-radius: 6px;
        z-index: 0;
        background-size: 300% 300%;
      }
    }

    button,
    a {
      background-color: #000000;
      // border: 1px solid #aea25d;
      color: blanchedalmond;
      font-size: 16px;
      padding: 20px 8px 20px;
      border-radius: 5px;
      letter-spacing: 1px;
      line-height: 1.1;
      display: block;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 100%;
      font-family: "BudmoJiggler-Regular";
      border: transparent;

      &:hover {
        border-color: #523664;
      }

      img {
        display: block;
        margin: 0 auto 15px;
        height: 50px;
        width: 50px;
        background-color: rgba(0, 0, 0, 0.169);
        object-fit: contain;
      }

      span {
        display: block;
      }
    }
  }
}

// Connect Wallet css End
.informationMU {
  .benefitcontent {
    border: 1px solid dimgrey;
    padding: 20px;
  }
  .infotitle {
    margin-bottom: 30px;

    h2 {
      font-size: 48px;
    }
  }

  .infoimage {
    img {
      max-height: 500px;
    }
  }

  .infotxt {
    h3 {
      font-size: 42px;
      margin-bottom: 40px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
        0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
      animation: pulsate 1.5s infinite alternate;
      font-weight: 400;
    }

    p {
      letter-spacing: 1px;
      color: #c9c9c9;
      text-align: justify;
      font-size: 15px;

      span {
        color: #ffffff;
        font-weight: 600;
        font-size: 19px;
      }
    }
  }
}

.Toastify__progress-bar-theme--light {
  background: #ce86f9;
}

.Toastify__toast {
  color: #000000;
  font-size: 18px;
}

.bgVideoBody {
  object-fit: cover;
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.1;
  mix-blend-mode: difference;

  &,
  video {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.countDownMu {
  text-align: center;
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 42px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
    0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
  font-family: "BudmoJiggler-Regular";

  b {
    display: flex;
    text-align: center;
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 20px;
    margin: 0px 10px;
    border-radius: 6px;
    position: relative;

    span {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: "Sorts Mill Goudy", serif;
    }
  }

  p {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 15px auto 0px;
    text-align: center;
    font-size: 16px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;

    // span {
    //     display: block;
    // }
  }
}
.web3gamesfix {
  position: fixed;
  top: calc(50% - 140px);
  right: 0px;
  transform: translate(0%, -50%);
  z-index: 999;
  width: 50px;
  background-color: #000000;
  padding: 1px 5px 6px;
  border-radius: 7px 0px 0px 7px;
  box-shadow: inset 2px 0px 11px -3px #ffffff;
}
.web3gamesfix a::before {
  content: "Live";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: 0px;
  left: 8px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}
.web3gamesfix a::after {
  content: "Web3";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: -2px;
  left: 4px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}
.web3gamesfix a {
  display: block;
  text-decoration: none;
}

.web3gamesfix img {
  display: block;
  width: 100%;
  height: auto;
}
// loader-css
.loaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
//news section
// .newsbox{
//   position: relative;
//   border: 1px solid #43394d;
//   margin: 0 20px;
//   height: 100%;
//   padding: 30px;
//   border-radius: 10px;
//   background-color: #17092d75;
//   background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.0509803922) 0%, rgba(255, 255, 255, 0) 73.86%);
//   border-style: solid;
//   border-width: 1px 1px 0px 1px;
//   // border-color: rgba(255, 255, 255, 0.1019607843);
//   border-color: rgb(0 0 0 / 10%);
//   border-radius: 16px 16px 0px 0px;
//   width: calc(100% - 20px);
// }
.marquee {
  font-size: 18px;
  color: #fff;
  height: 40px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  line-height: 1.2;
  margin-bottom: 15px;
  padding: 0px;
  letter-spacing: 2px;
  font-weight: 400;
  display: block;
  background-image: linear-gradient(to right, #ffffff00, #E900C2, #ffffff00);
  text-transform: uppercase;
  &::before {
    content: "";
    background-image: url(../images/marqueemarketing.gif);
    display: block;
    width: 45px;
    height: 100%;
    background-size: contain;
    position: absolute;
    left: 5px;
    top: 0;
    background-repeat: no-repeat;
    z-index: 2;
  }
  &::after {
    content: "";
    background-image: linear-gradient(to right, #121212 70%, transparent);
    display: block;
    width: 100px;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0;
    z-index: 1;
  }
  b {
    color: #FFD196;
  }
  img {
    display: inline-block;
    width: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    position: relative;
    top: 0px;
    animation: zoom-in-zoom-out 1s ease-out infinite;
    height: 26px;
  }
  marquee {
    position: relative;
    z-index: 1;
    margin-top: 8px;
  }
  @keyframes zoom-in-zoom-out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.newsbox{
  position: relative;
  border: 1px solid #43394d;
  margin: 0 20px;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(23, 9, 45, 0.4588235294);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 16px;
  width: calc(100% - 20px);
  box-shadow: inset 0 0 35px 3px #ffeb3b61;
}
h2.nftsubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nftsubtitle {
  span{
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 22px;
    margin-left: 8px;
  }
}

.roundbg {
  position: relative;
  height: 90px;
  width: 120px;
  margin: 0 auto;

  img {
    position: relative;
    filter: drop-shadow(2px 4px 6px black);
   
  }
}
.label1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgb(255 234 54 / 55%);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 5px 5px 5px #000;
}
.infolabel {
  margin-top: 20px;
  .btnMU, .btn-btnMU {
    font-size: 16px;
}
}
.news, .nftBalance {
  h3 {
    font-size: 42px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 0 7px #0000, 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe,
      0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
    animation: pulsate 1.5s infinite alternate;
    font-weight: 400;
  }
}
.newssec {
  .viewbtn {
    background-color: #ffff00f2;
    padding: 10px 22px;
    color: #000;
    font-weight: bolder;
    text-decoration: none;
    border-radius: 20px;
  }
  .alice-carousel__dots-item:not(.__custom) {
    background-color: #ffffff;
    border-radius: 2px;
    height: 15px;
    transform: skew(-10deg);
    width: 15px;
    opacity: 1;
  }
  
  .alice-carousel__dots-item:not(.__custom):hover,
  .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #c8b629;
    opacity: 1;
  }
  .alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    display: inline-flex;
    cursor: pointer;
    // padding: 10px;
    margin: 0;
    color: #fff;
    // font-size: 16px;
    // background: #23143b;
    // border-radius: 50%;
    // height: 40px;
    // width: 40px;
    // justify-content: center;
    font-weight: bolder;
    span{
      height: 40px;
      width: 40px;
      justify-content: center;
      border-radius: 50%;
      align-items: center;
      display: flex;
      font-size: 16px;
      background: #23143b;
    }
  }
  .alice-carousel__prev-btn, .alice-carousel__next-btn {
    display: inline-block;
    box-sizing: border-box;
    width: fit-content;
    padding: 10px 5px;
    position: absolute;
    right: 0;
    top: -71px;
  }
  .alice-carousel__prev-btn {
    text-align: right;
    right: 50px;
  }
  .alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover {
    color: unset;
}
@media (max-width:576px) {
  .alice-carousel__prev-btn, .alice-carousel__next-btn {
    display: none;
  }
}
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  /* Loader styles */
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
}

/* Loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.imagebox {
  max-width: 160px;
  margin: 30px auto;
  border-radius: 50%;
  text-align: center;
}
.imagebox img {
  animation: move 3s linear infinite;
  transition: all 0.5s;
}
.titlebg {
  color: #2196F3;
  font-size: 25px;
}
.joinbtn{
  background-color: #03A9F4;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bolder;
}
.MuiPaper-root {
  background-color: #000 !important;
  box-shadow: inset 0 0 19px #f0e5e5d4 !important;
  padding-inline: 7px;
  padding-block: 7px;
  max-width: 650px !important;
  border-radius: 14px !important;
  position: relative !important;
  background: #f00;
}
.addbgneon .MuiPaper-root {
 &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg,#03A9F4,#FF0058);
}
&::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg,#03A9F4,#FF0058);
    filter: blur(30px);
}
}
.MuiTabs-flexContainer {
justify-content: center;
}
.boxcontent {
  background: #000;
  position: relative;
  z-index: 99;
  border-radius: 6px;
  overflow: auto;
}
.InputContainer {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  padding-inline: 5px;
}
.MuiButtonBase-root {
  color: #Fff !important;
  background: #2692E0 !important;
}
.MuiTabs-scroller {
  .MuiTabs-indicator {
    background-color: #fff;
  }
}
.MuiDialogContent-root {
  .MuiTabPanel-root {
    padding: 15px 0px;
  }
}
.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9em;
  caret-color: rgb(255, 81, 0);
}
.labelforsearch {
  cursor: text;
  padding: 0px 12px;
}
.searchIcon {
  width: 13px;
}
.border {
  height: 40%;
  width: 1.3px;
  background-color: rgb(223, 223, 223);
}
.micIcon {
  width: 12px;
}
.micButton {
  /* padding: 0px 15px 0px 12px; */
  border: none;
  background-color: transparent;
  /* height: 40px; */
  cursor: pointer;
  transition-duration: 0.3s;
  max-width: 35px;
  border-radius: 50%;
  background: #2196F3;
  height: 35px;
  margin-left: 30px;
}
.micButton svg {
  max-width: 100%;
  height: auto;
  filter: invert(1);
}
.searchIcon path {
  fill: rgb(114, 114, 114);
}
.micIcon path {
  fill: rgb(255, 81, 0);
}
.micButton:hover {
  filter: brightness(0.8);
  transition-duration: .3s;
}
.popuptitle {
  font-size: 35px;
}
.iconcolor{
  color: #03A9F4;
 width: 20px !important;
}
.css-ypiqx9-MuiDialogContent-root{
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 26px !important;
  padding-top: 10px !important;
}
@keyframes move {
   0%{transform: scale(0.7)}
  50%{transform: scale(1)}
  100%{transform: scale(0.7)}
}
@media (max-width:768px) {
  .imagebox {
      max-width: 160px;
      margin: 0 auto;
  }
  .popuptitle {
    font-size: 28px;
  }
}

// Media Query Css Start
@import "media"; // Media Query Css End
