@media only screen and (max-width: 992px) {

    .container,
    .container-sm {
        max-width: calc(100% - 20px);
    }
    .lazyload-wrapper {
        max-width: 100%;
    }

    .topInformation h1 {
        font-size: 35px;
        text-align: center;
    }
    .buttonGroup {
        justify-content: center;
    }

    // .informationMU .infotxt h3 {
    //     font-size: 32px;
    // }


    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 150%;
    }
}

@media only screen and (max-width: 767px) {
   
    header .logoMainHead {
        width: 120px;
    }
    .btnMU, .btn-btnMU {
        font-size: 16px;
    }

    .informationMU .infotxt {
        text-align: center;
    }

    .informationMU .infotxt p {
        font-size: 13px;
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 70%;
    }
    .nftMain .stakebtn {
        padding: 8px 10px;
        border-radius: 9px;
    }

    .countDownMu p {
        font-size: 14px;
    }
    .listViewBx .inrInfoBx img {
        max-width: 130px;
    }
    .footer_bottom_inner {
        flex-direction: column;
        text-align: center;
    }
    .copyright_right {
        margin-bottom: 12px;
    }
    .listViewBx .inrInfoBx .date {
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
        font-size: 23px;
    }
    .listViewBx .inrInfoBx span.countbox strong {
        font-size: 10vw !important;
        letter-spacing: 0.2rem;
        color: #181f1b;
        text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #72eeaf, 0 0 2px #72eeaf, 0 0 0 #72eeaf, 0 0 2px #72eeaf, 0 0 11px #72eeaf;
    }
    // .nftBalance .balanceViewBx .balancelist {
    //     padding: 23px;
    // }
    // .nftMain {
    //     padding: 20px 0px;
    // }
}

@media only screen and (max-width: 575px) {

   

    .popular_gaming_inner .swiper-button-next,
    .popular_gaming_inner .swiper-button-prev {
        height: 35px;
        width: 35px;
    }
    .nftMain .nftheadline {
        font-size: 35px;
    }
    .topInformation h1 {
        font-size: 35px;
    }
    .news h3 {
        font-size: 35px;
    }
    .nftBalance h3 {
        font-size: 35px;
    }

    .informationMU .infotxt h3 {
        font-size: 35px;
    }

    .topInformation .btnMU,
    .topInformation .btn-btnMU {
        font-size: 16px;
        min-width: 150px;
    }

    .listViewBx.ms\:mt-5.pt-5 {
        padding-top: 0 !important;
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 44vw;
    }


    .nftBalance .balanceViewBx ul li {
        margin-bottom: 15px;
        font-size: 14px;
    }

    main section {
        margin-bottom: 30px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 14px;
    }
    .footer_bottom_link_menu ul li {
        margin-right: 0;
        padding-right: 8px;
    }
    .footer_social ul li a {
        width: 22px;
        height: 22px;
    }

    footer .copyright span {
        display: block;
        margin-top: 4px;
    }

    footer .footer {
        padding: 35px 0px 15px;
    }

    .connectWalletToggle .modal-content .modal-body {
        padding: 0px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 20px 0px;
    }

    header .header-brand img {
        max-width: 155px;
    }

    .topInformation h1 .lstwld {
        display: block;
    }

    .topInformation {
        margin-bottom: 20px;
    }

    .countDownMu {
        font-size: 35px;

        p {
            font-size: 13px;

            span {
                display: block;
            }
        }
    }

    footer .social-icons a {
        width: 40px;
        height: 40px;
        margin: 0px 5px !important;
    }

}

@media only screen and (max-width: 390px) {
    .nftBalance .balanceViewBx ul li {
        font-size: 12px;
    }

    .topInformation .btnMU,
    .topInformation .btn-btnMU {
        min-width: 105px;
    }

    .header {

        .btnMU,
        .btn-btnMU {
            font-size: 16px;
        }
    }

    .listViewBx .inrInfoBx .imginrBx::before {
        background-size: 60vw;
    }

    .listViewBx .inrInfoBx img {
        max-width: 70px;
    }

    .connectWalletToggle .popupbtngrp button img,
    .connectWalletToggle .popupbtngrp a img {
        height: 40px;
        width: 40px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 12px;
        padding: 10px 4px 10px;
    }

    .connectWalletToggle .modal-content .modal-header {
        padding: 15px 10px;
    }

    .connectWalletToggle .modal-content .modal-title {
        font-size: 18px;
    }

    header .header-brand img {
        max-width: 125px;
    }

    .header {

        .btnMU span,
        .btn-btnMU span {
            display: block;
            padding: 0px 6px;
            font-size: 16px;
        }
    }

    main {
        padding: 15px 0px;
    }

}
@media only screen and (min-width:767px) {
    .popular_gaming_inner>.swiper .swiper-slide.swiper-slide-next {
        transform: scale(1.2);
        position: relative;
        z-index: 9
    }

    .popular_gaming_inner>.swiper {
        transform: scale(.95);
        padding-top: 60px;
        padding-bottom: 90px;
        padding-left: 30px;
        padding-right: 30px
    }
}