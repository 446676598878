@charset "UTF-8";
body {
  font-size: 14px;
  background-color: #000;
  line-height: 1.4;
  color: #ffffff;
  position: relative;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "BudmoJiggler-Regular";
  src: url(../fonts/BudmoJiggler-Regular.eot);
  src: url("../fonts/BudmoJiggler-Regular.eot?#iefix") format("embedded-opentype"), url(../fonts/BudmoJiggler-Regular.woff2) format("woff2"), url(../fonts/BudmoJiggler-Regular.woff) format("woff"), url(../fonts/BudmoJiggler-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.bgBody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.col-6 {
  flex-basis: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  flex-basis: 0 0 100%;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BudmoJiggler-Regular";
  font-weight: 700;
  line-height: 1;
}

.btnMU,
.btn-btnMU {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
  background-color: #000109;
  text-decoration: none;
  border: transparent;
  --cui-btn-active-bg: transparent;
}
.btnMU::after,
.btn-btnMU::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 0.9s ease alternate infinite;
  background-size: 300% 300%;
}
.btnMU span,
.btn-btnMU span {
  display: block;
  padding: 0px 12px;
}
.btnMU:hover,
.btn-btnMU:hover {
  background: #050107;
}

.btn-btnMU:nth-child(1)::after {
  animation: unset;
}

.btn-btnMU:nth-child(2)::after {
  animation: unset;
}

.btn-btnMU:nth-child(3)::after {
  animation: unset;
}

.btn-btnMU:nth-child(4)::after {
  animation: unset;
}
@keyframes animatedgradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
    opacity: 0;
  }
  100% {
    background-position: 0 50%;
  }
}
img {
  max-width: 100%;
  height: auto;
  width: auto;
}

a {
  color: #83c8ff;
  outline: none;
}
a:hover {
  color: #ffbebe;
}

#root {
  min-height: 100vh;
}

main {
  padding: 40px 0px;
}
main section {
  margin-bottom: 80px;
}
main section:nth-last-child(1) {
  margin-bottom: 0px;
}

header {
  position: sticky;
  top: 0px;
  left: 0;
  width: 100%;
  background-image: linear-gradient(#020315, rgba(2, 3, 21, 0) 100%);
  z-index: 2;
}
header .header {
  padding: 0px 0px;
  min-height: auto;
  background-color: transparent;
  border: none;
  position: relative;
}
header .header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #020314;
  filter: blur(30px);
}
header .header-brand img {
  width: 100%;
  max-width: 200px;
}
header .header-nav a {
  padding: 0px 10px;
  text-transform: uppercase;
  text-decoration: none;
}
header .header-nav a.active {
  color: #ffbebe;
}
header .bgBody {
  position: absolute;
}
header .logoMainHead {
  width: 140px;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}

footer {
  position: sticky;
  top: 100%;
  left: 0;
  width: 100%;
}
footer .copyright {
  color: #ffffff;
}
footer .footer {
  background-color: transparent;
  border: none;
  padding: 60px 0px 15px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
footer .social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .social-icons a {
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #83c8ff;
  border: 1px solid #83c8ff;
  border-radius: 3px;
}
footer .social-icons a:hover {
  border-color: #ffbebe;
  color: #ffbebe;
}

.footer_bottom {
  padding: 20px 0;
  border-top: 1px solid #404040;
  background-color: #000;
}

.copyright_right p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #aaa6b9;
  font-weight: 500;
  text-transform: uppercase;
}
.copyright_right a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.footer_social ul {
  margin-bottom: 0px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer_social li {
  margin-right: 10px;
  padding-right: 20px;
  position: relative;
}
.footer_social li::before {
  position: absolute;
  content: "||";
  right: 0;
  top: 50%;
  transform: translatey(-50%);
}
.footer_social a {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  text-align: center;
}
.footer_social svg {
  width: 100%;
  height: 100%;
}

.topInformation {
  margin-bottom: 30px;
}
.topInformation h1 {
  font-size: 55px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1.3;
  font-family: "BudmoJiggler-Regular";
  margin-bottom: 30px;
  text-align: left;
  color: #3c2900;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656, 0 0 11px #ffe656;
}
.topInformation h1 .nftxt {
  color: #db9eff;
}
.topInformation .btnMU,
.topInformation .btn-btnMU {
  font-size: 20px;
}

.nftmainimg img {
  max-height: 700px;
}

.listViewBx {
  background-color: #000;
  border-radius: 6px;
  position: relative;
}
.listViewBx::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.listViewBx .inrInfoBx {
  text-align: center;
  margin: 0 auto;
}
.listViewBx .inrInfoBx .imginrBx {
  position: relative;
  margin: 0 auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.listViewBx .inrInfoBx .date {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 35px;
}
.listViewBx .inrInfoBx span.countbox strong {
  font-size: clamp(1rem, 7vw, 130px);
  letter-spacing: 0.6rem;
  color: #181f1b;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #72eeaf, 0 0 2px #72eeaf, 0 0 0 #72eeaf, 0 0 2px #72eeaf, 0 0 11px #72eeaf;
}
.listViewBx .inrInfoBx p {
  font-size: 19px;
  margin-bottom: 5px;
}
.listViewBx .inrInfoBx strong {
  font-size: 25px;
  display: block;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.listViewBx .inrInfoBx img {
  max-width: 100px;
  margin: 0 auto 0px;
  display: block;
  position: relative;
  animation: zoominout 3s alternate infinite;
}
@keyframes zoominout {
  0%, 100% {
    transform: scale(1) rotate(-5deg);
  }
  50% {
    transform: scale(0.95) rotate(0);
  }
}

.lazyload-wrapper {
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto;
}
.lazyload-wrapper video {
  transform: scale(1);
  mix-blend-mode: lighten;
}

.lazyload-wrapper svg {
  mix-blend-mode: difference;
}

.nftBalance .balanceViewBx .balancewrap {
  margin-bottom: 30px;
  border-radius: 0px;
  box-shadow: 0px 0px 100px -80px #ffffff;
  border-radius: 6px;
  height: 100%;
  position: relative;
  display: block;
  text-align: center;
  opacity: 1;
  border: 1px solid #020004;
  padding: 20p;
  background-size: contain;
  background-color: #000;
}
.nftBalance .balanceViewBx .balancewrap::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  border-radius: 6px;
  background-size: 300% 300%;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.nftBalance .balanceViewBx .balancelist:first-child {
  border-radius: 6px 6px 0 0px;
}
.nftBalance .balanceViewBx .balancelist:last-child {
  border-radius: 0px 0px 6px 6px;
}
.nftBalance .balanceViewBx .balancelist {
  position: relative;
  padding: 10px 20px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  background: rgba(58, 47, 47, 0.23);
}
.nftBalance .balanceViewBx .balancelist span {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
}
.nftBalance .balanceViewBx .balancelist:nth-last-child(1) {
  margin-bottom: 0px;
}
.nftBalance .balanceViewBx .balancelist svg {
  margin-left: 10px;
  width: 22px;
  height: auto;
  display: inline-block;
  cursor: pointer;
}
.nftBalance .balanceViewBx .gifbox {
  max-width: 120px;
  margin: 12px auto;
  z-index: 1;
  position: relative;
  background: rgba(58, 47, 47, 0.46);
  border-radius: 50%;
}
.nftBalance .balanceViewBx .gifbox img {
  width: 100%;
  height: auto;
}

.nftMain .nftheadline {
  font-size: 42px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
}
.nftMain .leftinfo {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.nftMain .rightinfo {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.nftMain .stakebtnarea {
  position: relative;
  padding: 22px;
  text-align: center;
}
.nftMain .stakebtnarea span {
  font-size: 16px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 500;
  color: #fff;
  display: block;
}
.nftMain a {
  text-decoration: none;
}
.nftMain .stakebtn {
  padding: 9px 16px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
}
.nftMain .stakebtn::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  background-size: 300% 300%;
}
.nftMain .popular_gaming_inner .col-3 {
  flex-basis: 0 0 25%;
  max-width: 25%;
}
.nftMain .popular_gaming_inner .swiper-button-prev {
  background-color: #873787;
  cursor: pointer;
  height: 50px;
  left: 0;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 9999999;
}
.nftMain .popular_gaming_inner .swiper-button-prev::before {
  align-items: center;
  content: "↞";
  display: flex;
  font-size: 36px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.nftMain .popular_gaming_inner .swiper-button-next {
  background-color: #873787;
  cursor: pointer;
  height: 50px;
  position: absolute;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 9999999;
}
.nftMain .popular_gaming_inner .swiper-button-next::before {
  align-items: center;
  content: "↠";
  display: flex;
  font-size: 36px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.nftMain .popular_gaming_inner .nftsvideo {
  border: 1px solid #686868;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(#000, #000) padding-box, linear-gradient(145deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8) border-box;
  border: 2px solid transparent;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transform-origin: right bottom;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
.nftMain .popular_gaming_inner .nftboxtitle {
  align-items: center;
  background-color: #686868;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  left: -60px;
  padding: 7px 5px;
  position: absolute;
  text-align: center;
  top: 40px;
  transform: rotate(-55deg);
  width: 200px;
  z-index: 1;
}
.nftMain .popular_gaming_inner .swiper-slide {
  overflow: hidden;
}

.stakebtn {
  padding: 5px 5px;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  z-index: 9999999;
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
}
.stakebtn::after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  background-size: 300% 300%;
}

.connectWalletToggle {
  background-color: rgba(0, 0, 0, 0.8);
}
.connectWalletToggle .modal-dialog {
  max-width: 450px;
}
.connectWalletToggle .modal-content {
  color: #ffffff;
  background-color: #0a0a0a;
  box-shadow: 0px 0px 70px -40px #666666;
  padding: 0px 20px;
}
.connectWalletToggle .modal-content::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
.connectWalletToggle .modal-content .modal-header {
  align-items: flex-start;
  border: none;
  padding: 20px 10px;
}
.connectWalletToggle .modal-content .modal-body {
  padding: 10px;
}
.connectWalletToggle .modal-content button.btn-close {
  outline: none;
  box-shadow: none !important;
  opacity: 1;
  filter: invert(1);
}
.connectWalletToggle .modal-content .modal-title {
  font-size: 24px;
  margin-bottom: 0px;
  font-family: "BudmoJiggler-Regular";
  color: yellow;
  text-shadow: 0.3px 0.3px 0.3px #111;
  letter-spacing: 0.11em;
  color: #3c2900;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #ffe656, 0 0 2px #ffe656, 0 0 0 #ffe656, 0 0 2px #ffe656, 0 0 11px #ffe656;
}
.connectWalletToggle .modal-content .modal-title p {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 400;
  margin: 10px 0px 0px;
  text-shadow: none;
}
.connectWalletToggle .popupbtngrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.connectWalletToggle .popupbtngrp .btngrp {
  flex-basis: calc(33.33% - 15px);
  max-width: calc(33.33% - 15px);
  margin: 0px 7px 20px;
  display: block;
  position: relative;
}
.connectWalletToggle .popupbtngrp .btngrp::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: 0;
  background-size: 300% 300%;
}
.connectWalletToggle .popupbtngrp button,
.connectWalletToggle .popupbtngrp a {
  background-color: #000000;
  color: blanchedalmond;
  font-size: 16px;
  padding: 20px 8px 20px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 1.1;
  display: block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 100%;
  font-family: "BudmoJiggler-Regular";
  border: transparent;
}
.connectWalletToggle .popupbtngrp button:hover,
.connectWalletToggle .popupbtngrp a:hover {
  border-color: #523664;
}
.connectWalletToggle .popupbtngrp button img,
.connectWalletToggle .popupbtngrp a img {
  display: block;
  margin: 0 auto 15px;
  height: 50px;
  width: 50px;
  background-color: rgba(0, 0, 0, 0.169);
  -o-object-fit: contain;
     object-fit: contain;
}
.connectWalletToggle .popupbtngrp button span,
.connectWalletToggle .popupbtngrp a span {
  display: block;
}

.informationMU .benefitcontent {
  border: 1px solid dimgrey;
  padding: 20px;
}
.informationMU .infotitle {
  margin-bottom: 30px;
}
.informationMU .infotitle h2 {
  font-size: 48px;
}
.informationMU .infoimage img {
  max-height: 500px;
}
.informationMU .infotxt h3 {
  font-size: 42px;
  margin-bottom: 40px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
}
.informationMU .infotxt p {
  letter-spacing: 1px;
  color: #c9c9c9;
  text-align: justify;
  font-size: 15px;
}
.informationMU .infotxt p span {
  color: #ffffff;
  font-weight: 600;
  font-size: 19px;
}

.Toastify__progress-bar-theme--light {
  background: #ce86f9;
}

.Toastify__toast {
  color: #000000;
  font-size: 18px;
}

.bgVideoBody {
  -o-object-fit: cover;
     object-fit: cover;
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 0.1;
  mix-blend-mode: difference;
}
.bgVideoBody,
.bgVideoBody video {
  -o-object-fit: cover;
  object-fit: cover;
}

.countDownMu {
  text-align: center;
  margin: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 42px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
  font-family: "BudmoJiggler-Regular";
}
.countDownMu b {
  display: flex;
  text-align: center;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 20px;
  margin: 0px 10px;
  border-radius: 6px;
  position: relative;
}
.countDownMu b span {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  font-family: "Sorts Mill Goudy", serif;
}
.countDownMu p {
  flex: 0 0 100%;
  max-width: 100%;
  margin: 15px auto 0px;
  text-align: center;
  font-size: 16px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.web3gamesfix {
  position: fixed;
  top: calc(50% - 140px);
  right: 0px;
  transform: translate(0%, -50%);
  z-index: 999;
  width: 50px;
  background-color: #000000;
  padding: 1px 5px 6px;
  border-radius: 7px 0px 0px 7px;
  box-shadow: inset 2px 0px 11px -3px #ffffff;
}

.web3gamesfix a::before {
  content: "Live";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: 0px;
  left: 8px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}

.web3gamesfix a::after {
  content: "Web3";
  color: #ff7171;
  font-size: 12px;
  position: relative;
  top: -2px;
  left: 4px;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: bold;
}

.web3gamesfix a {
  display: block;
  text-decoration: none;
}

.web3gamesfix img {
  display: block;
  width: 100%;
  height: auto;
}

.loaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.marquee {
  font-size: 18px;
  color: #fff;
  height: 40px;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  line-height: 1.2;
  margin-bottom: 15px;
  padding: 0px;
  letter-spacing: 2px;
  font-weight: 400;
  display: block;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #E900C2, rgba(255, 255, 255, 0));
  text-transform: uppercase;
}
.marquee::before {
  content: "";
  background-image: url(../images/marqueemarketing.gif);
  display: block;
  width: 45px;
  height: 100%;
  background-size: contain;
  position: absolute;
  left: 5px;
  top: 0;
  background-repeat: no-repeat;
  z-index: 2;
}
.marquee::after {
  content: "";
  background-image: linear-gradient(to right, #121212 70%, transparent);
  display: block;
  width: 100px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  z-index: 1;
}
.marquee b {
  color: #FFD196;
}
.marquee img {
  display: inline-block;
  width: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  position: relative;
  top: 0px;
  animation: zoom-in-zoom-out 1s ease-out infinite;
  height: 26px;
}
.marquee marquee {
  position: relative;
  z-index: 1;
  margin-top: 8px;
}
@keyframes zoom-in-zoom-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.newsbox {
  position: relative;
  border: 1px solid #43394d;
  margin: 0 20px;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: rgba(23, 9, 45, 0.4588235294);
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 16px 16px;
  width: calc(100% - 20px);
  box-shadow: inset 0 0 35px 3px rgba(255, 235, 59, 0.3803921569);
}

h2.nftsubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nftsubtitle span {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 22px;
  margin-left: 8px;
}

.roundbg {
  position: relative;
  height: 90px;
  width: 120px;
  margin: 0 auto;
}
.roundbg img {
  position: relative;
  filter: drop-shadow(2px 4px 6px black);
}

.label1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgba(255, 234, 54, 0.55);
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 5px 5px 5px #000;
}

.infolabel {
  margin-top: 20px;
}
.infolabel .btnMU, .infolabel .btn-btnMU {
  font-size: 16px;
}

.news h3, .nftBalance h3 {
  font-size: 42px;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0 #bc13fe, 0 0 2px #bc13fe, 0 0 11px #bc13fe;
  animation: pulsate 1.5s infinite alternate;
  font-weight: 400;
}

.newssec .viewbtn {
  background-color: rgba(255, 255, 0, 0.9490196078);
  padding: 10px 22px;
  color: #000;
  font-weight: bolder;
  text-decoration: none;
  border-radius: 20px;
}
.newssec .alice-carousel__dots-item:not(.__custom) {
  background-color: #ffffff;
  border-radius: 2px;
  height: 15px;
  transform: skew(-10deg);
  width: 15px;
  opacity: 1;
}
.newssec .alice-carousel__dots-item:not(.__custom):hover,
.newssec .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #c8b629;
  opacity: 1;
}
.newssec .alice-carousel__prev-btn-item, .newssec .alice-carousel__next-btn-item {
  display: inline-flex;
  cursor: pointer;
  margin: 0;
  color: #fff;
  font-weight: bolder;
}
.newssec .alice-carousel__prev-btn-item span, .newssec .alice-carousel__next-btn-item span {
  height: 40px;
  width: 40px;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  display: flex;
  font-size: 16px;
  background: #23143b;
}
.newssec .alice-carousel__prev-btn, .newssec .alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 5px;
  position: absolute;
  right: 0;
  top: -71px;
}
.newssec .alice-carousel__prev-btn {
  text-align: right;
  right: 50px;
}
.newssec .alice-carousel__prev-btn-item:hover, .newssec .alice-carousel__next-btn-item:hover {
  color: unset;
}
@media (max-width: 576px) {
  .newssec .alice-carousel__prev-btn, .newssec .alice-carousel__next-btn {
    display: none;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Loader styles */
}
.loader-container .loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

/* Loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.imagebox {
  max-width: 160px;
  margin: 30px auto;
  border-radius: 50%;
  text-align: center;
}

.imagebox img {
  animation: move 3s linear infinite;
  transition: all 0.5s;
}

.titlebg {
  color: #2196F3;
  font-size: 25px;
}

.joinbtn {
  background-color: #03A9F4;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bolder;
}

.MuiPaper-root {
  background-color: #000 !important;
  box-shadow: inset 0 0 19px rgba(240, 229, 229, 0.831372549) !important;
  padding-inline: 7px;
  padding-block: 7px;
  max-width: 650px !important;
  border-radius: 14px !important;
  position: relative !important;
  background: #f00;
}

.addbgneon .MuiPaper-root::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(315deg, #03A9F4, #FF0058);
}
.addbgneon .MuiPaper-root::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(315deg, #03A9F4, #FF0058);
  filter: blur(30px);
}

.MuiTabs-flexContainer {
  justify-content: center;
}

.boxcontent {
  background: #000;
  position: relative;
  z-index: 99;
  border-radius: 6px;
  overflow: auto;
}

.InputContainer {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  padding-inline: 5px;
}

.MuiButtonBase-root {
  color: #Fff !important;
  background: #2692E0 !important;
}

.MuiTabs-scroller .MuiTabs-indicator {
  background-color: #fff;
}

.MuiDialogContent-root .MuiTabPanel-root {
  padding: 15px 0px;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9em;
  caret-color: rgb(255, 81, 0);
}

.labelforsearch {
  cursor: text;
  padding: 0px 12px;
}

.searchIcon {
  width: 13px;
}

.border {
  height: 40%;
  width: 1.3px;
  background-color: rgb(223, 223, 223);
}

.micIcon {
  width: 12px;
}

.micButton {
  /* padding: 0px 15px 0px 12px; */
  border: none;
  background-color: transparent;
  /* height: 40px; */
  cursor: pointer;
  transition-duration: 0.3s;
  max-width: 35px;
  border-radius: 50%;
  background: #2196F3;
  height: 35px;
  margin-left: 30px;
}

.micButton svg {
  max-width: 100%;
  height: auto;
  filter: invert(1);
}

.searchIcon path {
  fill: rgb(114, 114, 114);
}

.micIcon path {
  fill: rgb(255, 81, 0);
}

.micButton:hover {
  filter: brightness(0.8);
  transition-duration: 0.3s;
}

.popuptitle {
  font-size: 35px;
}

.iconcolor {
  color: #03A9F4;
  width: 20px !important;
}

.css-ypiqx9-MuiDialogContent-root {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 26px !important;
  padding-top: 10px !important;
}

@keyframes move {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}
@media (max-width: 768px) {
  .imagebox {
    max-width: 160px;
    margin: 0 auto;
  }
  .popuptitle {
    font-size: 28px;
  }
}
@media only screen and (max-width: 992px) {
  .container,
  .container-sm {
    max-width: calc(100% - 20px);
  }
  .lazyload-wrapper {
    max-width: 100%;
  }
  .topInformation h1 {
    font-size: 35px;
    text-align: center;
  }
  .buttonGroup {
    justify-content: center;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 150%;
  }
}
@media only screen and (max-width: 767px) {
  header .logoMainHead {
    width: 120px;
  }
  .btnMU, .btn-btnMU {
    font-size: 16px;
  }
  .informationMU .infotxt {
    text-align: center;
  }
  .informationMU .infotxt p {
    font-size: 13px;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 70%;
  }
  .nftMain .stakebtn {
    padding: 8px 10px;
    border-radius: 9px;
  }
  .countDownMu p {
    font-size: 14px;
  }
  .listViewBx .inrInfoBx img {
    max-width: 130px;
  }
  .footer_bottom_inner {
    flex-direction: column;
    text-align: center;
  }
  .copyright_right {
    margin-bottom: 12px;
  }
  .listViewBx .inrInfoBx .date {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 23px;
  }
  .listViewBx .inrInfoBx span.countbox strong {
    font-size: 10vw !important;
    letter-spacing: 0.2rem;
    color: #181f1b;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0 #fff, 0 0 2px #fff, 0 0 2px #72eeaf, 0 0 2px #72eeaf, 0 0 0 #72eeaf, 0 0 2px #72eeaf, 0 0 11px #72eeaf;
  }
}
@media only screen and (max-width: 575px) {
  .popular_gaming_inner .swiper-button-next,
  .popular_gaming_inner .swiper-button-prev {
    height: 35px;
    width: 35px;
  }
  .nftMain .nftheadline {
    font-size: 35px;
  }
  .topInformation h1 {
    font-size: 35px;
  }
  .news h3 {
    font-size: 35px;
  }
  .nftBalance h3 {
    font-size: 35px;
  }
  .informationMU .infotxt h3 {
    font-size: 35px;
  }
  .topInformation .btnMU,
  .topInformation .btn-btnMU {
    font-size: 16px;
    min-width: 150px;
  }
  .listViewBx.ms\:mt-5.pt-5 {
    padding-top: 0 !important;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 44vw;
  }
  .nftBalance .balanceViewBx ul li {
    margin-bottom: 15px;
    font-size: 14px;
  }
  main section {
    margin-bottom: 30px;
  }
  .connectWalletToggle .popupbtngrp button,
  .connectWalletToggle .popupbtngrp a {
    font-size: 14px;
  }
  .footer_bottom_link_menu ul li {
    margin-right: 0;
    padding-right: 8px;
  }
  .footer_social ul li a {
    width: 22px;
    height: 22px;
  }
  footer .copyright span {
    display: block;
    margin-top: 4px;
  }
  footer .footer {
    padding: 35px 0px 15px;
  }
  .connectWalletToggle .modal-content .modal-body {
    padding: 0px;
  }
  .connectWalletToggle .modal-content .modal-header {
    padding: 20px 0px;
  }
  header .header-brand img {
    max-width: 155px;
  }
  .topInformation h1 .lstwld {
    display: block;
  }
  .topInformation {
    margin-bottom: 20px;
  }
  .countDownMu {
    font-size: 35px;
  }
  .countDownMu p {
    font-size: 13px;
  }
  .countDownMu p span {
    display: block;
  }
  footer .social-icons a {
    width: 40px;
    height: 40px;
    margin: 0px 5px !important;
  }
}
@media only screen and (max-width: 390px) {
  .nftBalance .balanceViewBx ul li {
    font-size: 12px;
  }
  .topInformation .btnMU,
  .topInformation .btn-btnMU {
    min-width: 105px;
  }
  .header .btnMU,
  .header .btn-btnMU {
    font-size: 16px;
  }
  .listViewBx .inrInfoBx .imginrBx::before {
    background-size: 60vw;
  }
  .listViewBx .inrInfoBx img {
    max-width: 70px;
  }
  .connectWalletToggle .popupbtngrp button img,
  .connectWalletToggle .popupbtngrp a img {
    height: 40px;
    width: 40px;
  }
  .connectWalletToggle .popupbtngrp button,
  .connectWalletToggle .popupbtngrp a {
    font-size: 12px;
    padding: 10px 4px 10px;
  }
  .connectWalletToggle .modal-content .modal-header {
    padding: 15px 10px;
  }
  .connectWalletToggle .modal-content .modal-title {
    font-size: 18px;
  }
  header .header-brand img {
    max-width: 125px;
  }
  .header .btnMU span,
  .header .btn-btnMU span {
    display: block;
    padding: 0px 6px;
    font-size: 16px;
  }
  main {
    padding: 15px 0px;
  }
}
@media only screen and (min-width: 767px) {
  .popular_gaming_inner > .swiper .swiper-slide.swiper-slide-next {
    transform: scale(1.2);
    position: relative;
    z-index: 9;
  }
  .popular_gaming_inner > .swiper {
    transform: scale(0.95);
    padding-top: 60px;
    padding-bottom: 90px;
    padding-left: 30px;
    padding-right: 30px;
  }
}/*# sourceMappingURL=main.css.map */